import { useMutation, useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { useGalleryIdBySlug } from '@app/feautres/gallery/hooks/use-gallery-id-by-slug';
import { QrView } from '@app/feautres/template/views/qr-view';
import { FC, useLayoutEffect } from 'react';

const templateDocument = graphql(`
  query qrTemplate($galleryId: UUID!) {
    template(galleryId: $galleryId) {
      qr {
        logoUrl
        logoWidth
        headerText
        inputText
        buttonText
        successText
        textColor
        color
        bgColor
        showBottomImg
      }
    }
  }
`);

const sendEmailByFilenameDocument = graphql(`
  mutation sendEmailByFilename($gallerySlug: String!, $uploadFilename: String!, $email: String!) {
    sendEmailByFilename(gallerySlug: $gallerySlug, uploadFilename: $uploadFilename, email: $email) {
      id
    }
  }
`);

interface QrSendProps {
  gallerySlug: string;
  uploadFilename: string;
}

export const QrSend: FC<QrSendProps> = ({ gallerySlug, uploadFilename }) => {
  const galleryId = useGalleryIdBySlug(gallerySlug);

  const { data: templateData } = useSuspenseQuery(templateDocument, { variables: { galleryId } });

  const [sendEmail, { data: emailData, loading, error }] = useMutation(sendEmailByFilenameDocument, { onError: () => {} });

  const handleSubmit = (email: string) => {
    sendEmail({ variables: { gallerySlug, uploadFilename, email } });
  };

  useLayoutEffect(() => {
    document.body.style.backgroundColor = templateData.template.qr.bgColor;
  }, [templateData]);

  return (
    <QrView
      template={templateData.template.qr}
      isLoading={loading}
      isSuccess={!!emailData}
      isError={!!error}
      onSubmit={handleSubmit}
    />
  );
};
