import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://3b937ab83b42d86a084cd3e94a689111@o4507856058449920.ingest.de.sentry.io/4507856120971344',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost:5555', /^https:\/\/qr\.picsane\.tech/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
