import { ApolloProvider } from '@apollo/client';
import { GlobalStyles } from '@app/styles/global';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { publicClient } from '@app/apollo/public-client';
import { theme } from '@app/styles/theme';
import { router } from './router';

export const App = () => {
  return (
    <ApolloProvider client={publicClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ApolloProvider>
  );
};
