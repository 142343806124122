import { createBrowserRouter } from 'react-router-dom';
import { QrSendPage } from './pages/qr-send';
import { Redirect } from '@app/components/redirect';

export const router = createBrowserRouter(
  [
    {
      path: '/:gallerySlug/:uploadFilename',
      element: <QrSendPage />,
    },
    { path: '*?', element: <Redirect url="https://picsane.tech/" /> },
  ],
  { basename: import.meta.env.DEV ? '/qr' : '/' },
);
