import { Boundary } from '@app/components/boundary';
import { QrSend } from '@app/qr/containers/qr-send';
import { useParams } from 'react-router-dom';
import { QrSendPageWrapper } from './qr-send-page.styles';

export const QrSendPage = () => {
  const gallerySlug = useParams().gallerySlug as string;
  const uploadFilename = useParams().uploadFilename as string;

  return (
    <QrSendPageWrapper>
      <Boundary>
        <QrSend gallerySlug={gallerySlug} uploadFilename={uploadFilename} />;
      </Boundary>
    </QrSendPageWrapper>
  );
};
